
import { System } from '@/modules/system';
import $app from '@/plugins/modules'
import { Component, Vue } from 'vue-property-decorator'
import { IVisit } from '../types';

@Component
export default class VisitReceipt extends Vue {
  system = $app.module(System);

  item: IVisit | null = null;
  patient: any = null;
  doctor: any = null;
  loading = false;

  get user() {
    return this.system.$store.getCurrentUser
  }

  get manipulations() {
    const items: Array<any> = [];
    if (this.item && this.item.manipulations) {
      this.item.manipulations.filter(m => m.state === 'D' || m.state === 'Y').forEach(m => {
        if (m.codeNVD && m.codeNVD.trim() !== '') {
          const codes = m.codeNVD.split(';')
          codes.forEach(c => {
            if (c && c.trim() !== '') {
              items.push({ done: m.done, code: c, tooth: m.tooth, surfaces: m.surfaces });
            }
          })
        }
      })
    }
    return items;
  }

  async load() {
    this.loading = true;
    try {
      this.item = await $app.get('/api/patients/' + this.$route.query.patient + '/visits/' + this.$route.query.id);
      this.patient = await $app.get('/api/patients/' + this.$route.query.patient);
      if (this.item) {
        this.doctor = await $app.get('/api/clinics/doctors/' + this.item.doctor);
      }
    } catch (err) {
      $app.pushError(err);
      throw err;
    }
    this.loading = false;
  }

  mounted() {
    this.load();
  }
}
